/* General styles for the home container */
@font-face {
    font-family: 'Quart';
    src: url('../assets/Quart-Regular.ttf') format('truetype'); /* Adjust the path as necessary */
  }

.all-container {
    display: block;
    align-items: flex-start;
    min-height: 100vh; /* Full screen height */
    background-color: #ffffff; /* Light grey background */
    padding:50px 15px 15px 20px;
    margin-top: 50px;
    box-sizing: border-box;
  }

.fullWidth {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .employment-history {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .employment-entry {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .employment-history-header {
    background-color: #007bff; /* Adjust the blue color as needed */
    color: #fff;
    font-size: 1.5rem;
    font-weight: bolder;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    background-color: #ffffff; /* Light grey background */
    padding: 20px;
    box-sizing: border-box;
  }

  .fa-button {
    background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  }