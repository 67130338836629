/* General styles for the home container */
@font-face {
    font-family: 'Quart';
    src: url('../assets/Quart-Regular.ttf') format('truetype'); /* Adjust the path as necessary */
  }

.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    background-color: #ffffff; /* Light grey background */
    padding: 20px;
    box-sizing: border-box;
  }

.fullWidth {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px;
    flex: 1;
    padding: 20px;
  }
  
  .left-column {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .left-column h1 {
    margin: 0;
    font-size: 4rem;
    font-weight: lighter;
    color: #1859DD;
  }
  
  .left-column h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: lighter;
  }

  .left-column p {
    font-size: 1rem;
    font-weight: lighter;
  }
  
  .right-column img {
    max-width: 100%;
    height: auto;
    border-radius: 10px; /* Optional: rounded corners for the image */
  }

  .background-video {
    max-width: 100%;
    height: auto;
    border-radius: 10px; /* Optional: rounded corners for the video */
  }
  
  .circular-image {
    width: 75%;  /* Set the width */
    height: 75%; /* Set the height equal to width to maintain a square */
    border-radius: 50%; /* Makes the image circular */
    object-fit: cover; /* Ensures the image scales well inside the circle */
  }
  
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .home-container {
      flex-direction: column-reverse;
    }
  
    .left-column,
    .right-column {
      width: 100%;
    }
  }
  