/* General styles for the navbar */
.navbar {
  width: 100%;
  background: linear-gradient(to bottom, #EBF3FF, #ffffff); /* Light grey to white gradient */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px; /* Add padding inside the navbar */
}

.logo img {
  height: 40px;
}

.nav-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #333; /* Change text color to a darker color for better contrast */
  font-size: 15px;
}

.nav-links a:hover {
  text-decoration: none; /* Underline on hover */
  background-color: #d3d3d3;
}

.nav-button {
  padding: 10px 20px;
  font-size: 15px;
  color: #333;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-button:hover {
  background-color: #ddd;
  transform: scale(1.05);
}

.nav-button:focus {
  outline: none;
  background-color: #ccc;
}

.hamburger {
  display: none;
  font-size: 20px;
  color: #333; /* Change icon color to a darker color for better contrast */
  cursor: pointer;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    background-color: #d3d3d3; /* Maintain background color for mobile menu */
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-links.active {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .nav-links li {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
  }
}
